import React, { Component } from 'react';
import { Row, Column } from 'simple-flexbox';
import { Panel } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Datetime from 'react-datetime';
import { Redirect } from 'react-router-dom';

import Alert from "@sm/components/custom/Alert";
import CustomButton from "@sm/components/custom/Button";
import Form from "@sm/core/Form";
import Multiselect from "@sm/components/custom/customMultiselect";
import PromptModal from '@modules/generateReport/PromptModal';
import Select from "@sm/components/custom/Select";
import Spinner from '@sm/components/Spinner';
import Toggler from '@sm/components/custom/Toggler';

import '@assets/css/mids.css';
import MidsIcon from '@assets/images/mids.png';

import { crudActions } from "@sm/services/crudActions";
import { trxExpirationTimeOptions } from '@sm/utils/trxExpirationTimeOptions';
import { addLogItem } from "@sm/utils/utils";
const store = require('@sm/reducers/index');

class ManageMid extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  };

  node = null;
  burgerNode = null;
  emailsRef = React.createRef();
  depositStatusToggleRef = React.createRef();
  payoutStatusToggleRef = React.createRef();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
    this.loadData();
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  getInitialState = () => {
    const initialState = {
      midForm: new Form({
        id: null,
        companyId: "",
        merchants: null,
        midName: "",
        trafficType: "",
        midUsage: "single",

        category: "",
        currency: "",
        countries: [],
        timeZone: "",
        expiredInMinutes: "",
        
        weekDaysSchedule: [],
        bankHolidaysSchedule: [],
        supportedCardTypes: [],
        
        dailyTransactions: null,
        weeklyTransactions: null,
        dailyVolume: null,
        weeklyVolume: null,
      }, [{
        name: "companyId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "merchants",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "midName",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "trafficType",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "category",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "countries",
        type: "isArray",
        rules: {
          required: true,
          min: 1
        }
      }, {
        name: "currency",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "timeZone",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "expiredInMinutes",
        type: "isNumber",
        rules: {
          required: false
        }
      }, {
        name: "weekDaysSchedule",
        type: "isArray",
        rules: {
          required: false
        }
      }, {
        name: "bankHolidaysSchedule",
        type: "isArray",
        rules: {
          required: false
        }
      }, {
        name: "supportedCardTypes",
        type: "isArray",
        rules: {
          required: true,
          min: 0
        }
      }, {
        name: "dailyTransactions",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "weeklyTransactions",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "dailyVolume",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "weeklyVolume",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }]),

      depositForm: new Form({
        pspId: "",
        accountId: "",
        paymentMethod: "",
        mode: "LIVE",
        enabled: false,

        minAmount: "",
        maxAmount: "",
        maxDailyLoad: "",

        rounding: "",
        updateAmount: ""
      }, [{
        name: "pspId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "accountId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "paymentMethod",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "mode",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "minAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxDailyLoad",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "rounding",
        type: "isNumber",
        rules: {
          min: 1,
          required: false
        }
      }, {
        name: "updateAmount",
        type: "isString",
        rules: {
          required: false
        }
      }]),

      payoutForm: new Form({
        pspId: "",
        accountId: "",
        paymentMethod: "",
        mode: "LIVE",
        enabled: false,

        minAmount: "",
        maxAmount: "",
        payoutWalletType: ""
      }, [{
        name: "pspId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "accountId",
        type: "isNumber",
        rules: {
          required: true
        }
      }, {
        name: "paymentMethod",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "mode",
        type: "isString",
        rules: {
          required: true
        }
      }, {
        name: "minAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "maxAmount",
        type: "isNumber",
        rules: {
          min: 0,
          required: true
        }
      }, {
        name: "payoutWalletType",
        type: "isString",
        rules: {
          required: true
        }
      }]),

      midSecurity: new Form({
        maxSameNamePerHour: null,
        maxSamePostalPerHour: null,
        maxSameEmailPerHour: null,
        maxNumberFailedTx: null,
        maxPendingPerCardPerPeriod: null,
        maxDeclinedPerCardPerPeriod: null,
        emails: []
      }, [{
        name: "maxSameNamePerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxSamePostalPerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxSameEmailPerHour",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxNumberFailedTx",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxPendingPerCardPerPeriod",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "maxDeclinedPerCardPerPeriod",
        type: "isNumber",
        rules: {
          min: 0,
          required: false
        }
      }, {
        name: "emails",
        type: "isArray",
        rules: {
          required: false,
          min: 0
        }
      }]),

      merchants: null,
      companiesTimezones: {},
      buyMethods: [],
      payoutMethods: [],
      payoutWalletTypes: [],
      timezones: [],
      companies: [],
      cardTypes: [],
      psps: [],
      accounts: [],
      pspUrlsData: [],

      foundMid: {},
      lookups: {},
      selectedLookups: {},
      isMidSecurityEnabled: false,
      testValuesPermitted: false,

      temporaryEmail: "",
      liveStatus: "always", //custom
      holidayControl: {
        holidayName: "",
        from: this.getPeriod(0).from.format("DD.MM.YYYY"),
        to: this.getPeriod(0).to.format("DD.MM.YYYY")
      },

      holidaysNewRowClicked: false,
      holidayErrors: {
        holidayName: false,
        from: false,
        to: false
      },

      timeStamp: {
        fromDateTimeStamp: this.getPeriod(0).from,
        toDateTimeStamp: this.getPeriod(0).to
      },

      weekDaysSchedule: [{
        dayName: "MONDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "TUESDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "WEDNESDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "THURSDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "FRIDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "SATURDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }, {
        dayName: "SUNDAY",
        id: null,
        fromDate: "00:00",
        toDate: "23:59"
      }],

      midsLinks: [{
        name: "ADD MIDs",
        url: "/add-mid",
      }],

      trafficTypes: [{
        value: "FTD",
        label: "FTD"
      }, {
        value: "MIXED",
        label: "Mixed",
        isDisabled: true
      }, {
        value: "TRUSTED",
        label: "Trusted"
      }],

      removeEmptyField: false,

      showAlert: false,
      alertType: "success",
      alertMessage: "",
      redirectToMids: false,
      isLoading: true,
      isStatusLoading: "",
      isSubmitting: false,
      submitTouched: false,
      isBurgerOpen: false,
      mode: "",
      showPromptModal: false,
      promptModalTitle: "",
      promptModalMessage: "",
      editableField: "",
      editableFieldValue: null,
      editableForm: "",
      logs: []
    };

    return initialState;
  };

  loadData = () => {
    const { midId } = this.props;
    const { midForm, depositForm, payoutForm, midSecurity } = this.state;

    Promise.all([
      (crudActions.get('v1/adminpanel/lookups')),
      (midId ? crudActions.get(`v1/mids/${midId}`) : Promise.resolve()),
      crudActions.get("v1/mids/timezones"),
      crudActions.get("v1/companies/clients"),
      crudActions.get("v1/mids/card-types")
    ]).then(
      (data) => {
        const lookups = data[0];
        const foundMid = data[1];
        const timezones = data[2];
        const companiesData = data[3];
        const cardTypes = data[4];
        let updateObject = {};

        if (lookups && timezones && companiesData) {
          (foundMid ? crudActions.get(`v1/adminpanel/company/${foundMid.companyId}`) : Promise.resolve()).then(
            (dropdowns) => {
              if (dropdowns) {
                updateObject = {
                  lookups: lookups,
                  foundMid,
                  selectedLookups: dropdowns.storedLookups,
                  midForm: Object.assign(midForm, foundMid, {
                    midUsage: "single"
                  }),
                  depositForm: Object.assign(depositForm, foundMid.depositSettings, {
                    mode: !foundMid ? lookups.pspUrlTypes[1].value : foundMid.depositSettings.mode ? foundMid.depositSettings.mode : "LIVE",
                    updateAmount: foundMid.updateAmount ? "final" : "initial"
                  }),
                  payoutForm: Object.assign(payoutForm, foundMid.payoutSettings, {
                    mode: !foundMid ? lookups.pspUrlTypes[1].value : foundMid.payoutSettings.mode ? foundMid.payoutSettings.mode : "LIVE"
                  }),
                  midSecurity: Object.assign(midSecurity, foundMid.midsSecurityDTO),
                  isMidSecurityEnabled: foundMid.midsSecurityDTO.enabled,
                  testValuesPermitted: foundMid.midsSecurityDTO.withTest,
                  isLoading: false,
                  liveStatus: foundMid.weekDaysSchedule.length !== 0 ? "custom" : "always"
                };

                if (foundMid.weekDaysSchedule.length) {
                  updateObject.weekDaysSchedule = foundMid.weekDaysSchedule;
                }
              } else {
                updateObject = {
                  lookups: lookups,
                  isLoading: false
                };
              }

              updateObject.timezones = timezones;
              const companies = companiesData.map(elem => elem.company);
              companies.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));

              updateObject = Object.assign(updateObject, {
                companies: companies,
                merchants: companiesData.reduce((accumulator, currentElem) => {
                  accumulator[currentElem.company.value] = currentElem.clients.map(elem => {
                    return {
                      value: parseInt(elem.client.value, 10),
                      label: elem.client.label
                    };
                  });
                  return accumulator;
                }, {}),
                companiesTimezones: companiesData.reduce((accumulator, currentElem) => {
                  accumulator[currentElem.company.value] = currentElem.defaultTimezone;
                  return accumulator;
                }, {}),
                psps: companiesData.reduce((accumulator, currentElem) => {
                  accumulator[currentElem.company.value] = currentElem.psps.map(psp => {
                    return {
                      value: parseInt(psp.value, 10),
                      label: psp.label,
                      buyMethods: psp.buyMethods,
                      payoutMethods: psp.payoutMethods,
                      shortName: psp.shortName,
                      currencies: psp.currencies,
                      timeZone: psp.timeZone
                    };
                  });
                  return accumulator;
                }, {}),
                accounts: companiesData.reduce((accumulator, currentElem) => {
                  accumulator[currentElem.company.value] = currentElem.accounts ? currentElem.accounts.map(account => {
                    return {
                      value: parseInt(account.id, 10),
                      label: account.label,
                      pspId: account.pspId,
                      enabled: account.enabled,
                      payoutEnabled: account.payoutEnabled,
                      companyId: account.companyId,
                      currency: account.currency,
                      payinMethod: account.payinMethod,
                      payoutMethod: account.payoutMethod,
                      maxAmountPayin: account.maxAmountPayin,
                      minAmountPayin: account.minAmountPayin,
                      maxAmountPayout: account.maxAmountPayout,
                      minAmountPayout: account.minAmountPayout,
                    };
                  }) : [];
                  return accumulator;
                }, {}),
                pspUrlsData: lookups.pspUrlTypes.map(pspUrlTypes => {
                  return {
                    value: pspUrlTypes.value,
                    label: pspUrlTypes.label
                  };
                }),
                cardTypes: cardTypes || [],
                payoutWalletTypes: lookups.payoutWalletTypes || []
              });

              this.setState(updateObject);
            }
          );
        }
      }
    );
  };

  loadCompanyAdminData = (companyId) => {
    const { midForm } = this.state;

    crudActions.get(`v1/adminpanel/company/${midForm.companyId}`).then(
      (dropdowns) => {
        if (dropdowns) {
          this.setState({
            selectedLookups: dropdowns.storedLookups
          });
        }
      }
    );
  };

  onBurgerClick = () => {
    this.setState({
      isBurgerOpen: !this.state.isBurgerOpen
    });
  };

  handleClick = (e) => {
    if ((this.node && this.node.contains(e.target)) || (this.burgerNode && this.burgerNode.contains(e.target))) {
      return;
    }

    this.handleClickOutside();
  };

  handleClickOutside = () => {
    if (!this.state.isBurgerOpen) {
      return;
    }
    this.setState({
      isBurgerOpen: false
    });
  };

  mapSelectedItems = (propertyName, lookupsName) => {
    const field = this.state.midForm[propertyName];
    let lookup = this.state.lookups[lookupsName];
    if (!lookup) {
      lookup = this.state[lookupsName];
    }

    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapMerchants = () => {
    const { midForm, merchants } = this.state;
    const field = midForm["merchants"];
    const lookup = merchants[midForm.companyId];
    return field.map(elem => {
      return lookup.find(lookupElem => elem === lookupElem.value);
    });
  };

  mapMethods = (methodType, form) => {
    const { selectedLookups, lookups, midForm, psps } = this.state;
    const currentForm = this.state[form];

    const paymentMethodTypeMap = {
      "PAYOUT": "payoutMethods",
      "BUY": "paymentMethods"
    };

    const paymentMethodLookupsMap = {
      "PAYOUT": "payoutMethods",
      "BUY": "buyMethods"
    };

    const methods = (selectedLookups[paymentMethodTypeMap[methodType]] || []).map(
      field => {
        const providers = !midForm.companyId ? [] : psps[midForm.companyId];
        const selectedProvider = providers.find(item => item.value === currentForm.pspId);
        if (selectedProvider && selectedProvider[paymentMethodLookupsMap[methodType]].includes(field)) {
          return lookups[paymentMethodLookupsMap[methodType]].find(elem => elem.value === field);
        }
        return {};
      }
    );

    const filtered = methods.filter(item => item.value);
    return filtered.sort((elemA, elemB) => elemA.label.localeCompare(elemB.label));
  };

  mapCountries = (methodType) => {
    const countriesMethodTypeMap = {
      "BUY": "country",
      "PAYOUT": "payoutCountries"
    };

    return countriesMethodTypeMap[methodType];
  };

  getAvailableTimeZones = () => {
    const { lookups, selectedLookups } = this.state;
    if (!lookups.timeZones || !selectedLookups.timeZones) {
      return [];
    }

    return lookups.timeZones.filter(zone => selectedLookups.timeZones.includes(zone.value));
  };

  filterAvailableAccounts = (formId) => {
    const { accounts, midForm } = this.state;
    const currentForm = this.state[formId];
    return accounts[midForm.companyId] && accounts[midForm.companyId].filter(
      item => item && item.pspId === currentForm.pspId && item.companyId === midForm.companyId && item.currency === midForm.currency
    );
  };

  formLookups = (propertyName, oneByOne, lookupsPropertyName) => {
    const { selectedLookups, lookups } = this.state;
    if (!selectedLookups[propertyName]) {
      return [];
    } else if (oneByOne) {
      return selectedLookups[propertyName].map(elem => {
        return {
          label: elem,
          value: elem
        };
      });
    }

    return selectedLookups[propertyName].map(elem => {
      return lookups[lookupsPropertyName].find(lookup => lookup.value === elem);
    });
  };

  isFieldDisabled = (fieldName) => {
    const viewOrEdit = this.props.viewOrEdit;

    if (!viewOrEdit) {
      return false;
    }

    return viewOrEdit === 1;
  };

  isAccountEnabled = (formId) => {
    const { accounts, midForm } = this.state;
    const currentForm = this.state[formId];

    const selectedAccount = accounts &&
      Object.keys(accounts).length > 0 &&
      accounts[midForm.companyId] &&
      accounts[midForm.companyId].find(acc => acc.value === currentForm.accountId);

    if (selectedAccount) {
      return formId === 'depositForm' ? selectedAccount.enabled : selectedAccount.payoutEnabled;
    }
    return false;
  };

  getPeriod(periodType) {
    const moment = Datetime.moment;

    switch (periodType) {
    case 1: // yesterday
      return {
        from: moment().utc().subtract(1, 'day'),
        to: moment().utc().subtract(1, 'day')
      };
    case 2: // this month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month()).date(1),
        to: moment().utc()
      };
    case 3: // last month
      return {
        from: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1).date(1),
        to: moment().utc().year(moment().utc().year()).month(moment().utc().month() - 1)
          .date(moment().utc().month(moment().utc().month() - 1).daysInMonth())
      };
    case 4: // this year
      return {
        from: moment().utc().year(moment().utc().year()).month(0).date(1),
        to: moment().utc()
      };
    case 5: // last year
      return {
        from: moment().utc().year(moment().utc().year() - 1).month(0).date(1),
        to: moment().utc().year(moment().utc().year() - 1).month(11).date(31)
      };
    case 0: // today
    default: // custom period
      return {
        from: moment().utc(),
        to: moment().utc()
      };
    }
  };

  onValueChange = (newValue, form, fieldName) => {
    const { midId } = this.props;
    let currentForm = this.state[form];
    const { liveStatus, midForm, submitTouched, accounts, psps } = this.state;
    const isNumber = currentForm.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";

    const prevValue = currentForm[fieldName];

    if (!newValue) {
      currentForm[fieldName] = newValue;
    } else if (newValue.value) {
      if ((fieldName === "pspId" || fieldName === "accountId" || fieldName === "paymentMethod" || fieldName === "trafficType" || fieldName === "payoutWalletType") &&
        midForm.id && currentForm[fieldName] && currentForm[fieldName] !== newValue.value) {
        const modalTitle = "Attention";
        let modalMessage = "Changing Account, Provider or Payment Method could affect related pending transaction. Do you want to continue?";
        if (fieldName === "trafficType") {
          modalMessage = "Are you sure you want to change the Traffic Type?";
        }
        if (fieldName === "payoutWalletType") {
          modalMessage = "Are you sure you want to change the Payout Wallet Type?";
        }
        this.setState({
          showPromptModal: true,
          promptModalTitle: modalTitle,
          promptModalMessage: modalMessage,
          editableField: fieldName,
          editableFieldValue: newValue,
          editableForm: form
        });
        return;
      } else {
        currentForm = Object.assign(currentForm, {
          [fieldName]: isNumber && !isNaN(parseFloat(newValue.value)) ? parseFloat(newValue.value) : newValue.value
        });
      }
    } else if (newValue.target) {
      currentForm = Object.assign(currentForm, {
        [fieldName]: isNumber && !isNaN(parseFloat(newValue.target.value)) ? parseFloat(newValue.target.value) : newValue.target.value
      });
    } else {
      currentForm = Object.assign(currentForm, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    const value = currentForm[fieldName];

    if (midId) {
      this.addLog(prevValue, value, fieldName, form, midId);
    }

    if (fieldName === "companyId") {
      this.loadCompanyAdminData(newValue.value);
      currentForm = Object.assign(currentForm, {
        pspId: "",
        paymentMethod: "",
        category: "",
        currency: "",
        countries: [],
        merchants: null,
      });
    } else if (fieldName === "maxDailyLoad") {
      const fieldRule = midForm.fieldRules.find(rule => rule.name === "timeZone");
      fieldRule.rules.required = currentForm.maxDailyLoad > 0 || liveStatus === "custom";
    } else if (fieldName === "pspId" && form === "payoutForm") {
      currentForm = Object.assign(currentForm, {
        accountId: "",
        paymentMethod: ""
      });
    } else if (fieldName === "pspId" && form === "depositForm") {
      midForm.timeZone = psps[midForm.companyId].find(psp => psp.value === newValue.value).timeZone || "";
      this.setState({
        midForm: midForm
      });
    } else if (fieldName === "accountId") {
      const account = accounts[midForm.companyId].find(acc => acc.value === currentForm.accountId);
      currentForm = Object.assign(currentForm, {
        paymentMethod: form === "depositForm" ? account.payinMethod : account.payoutMethod,
        minAmount: form === "depositForm" ? account.minAmountPayin : account.minAmountPayout,
        maxAmount: form === "depositForm" ? account.maxAmountPayin : account.maxAmountPayout,
      });
    }

    if (submitTouched) {
      currentForm.isFormValid();
    }

    this.setState({ [form]: currentForm });
  };

  addLog = (valueBefore, valueAfter, field, form, id) => {
    const { logs } = this.state;

    const type = form === "midForm" ? "MID_UPDATE" : (form === "depositForm" ? "MID_UPDATE_DEPOSIT" : "MID_UPDATE_PAYOUT");

    const updatedLogs = addLogItem(logs, type, valueBefore, valueAfter, field, id);

    this.setState({
      logs: updatedLogs
    });

    this.setState({
      logs
    });
  };

  handleConfirmPromptModal = () => {
    const { midId } = this.props;
    const { editableField, editableFieldValue, editableForm, submitTouched } = this.state;
    let currentForm = this.state[editableForm];

    const isNumber = currentForm.fieldRules.find(rule => rule.name === editableField).type === "isNumber";

    const prevValue = currentForm[editableField];
    const value = isNumber && !isNaN(parseFloat(editableFieldValue.value)) ? parseFloat(editableFieldValue.value) : editableFieldValue.value;

    currentForm = Object.assign(currentForm, {
      [editableField]: value
    });

    if (editableField === "accountId") {
      const accounts = this.filterAvailableAccounts(editableForm);
      const selectedAccount = accounts.length && editableFieldValue && accounts.find(acc => acc.value === editableFieldValue.value);
      if (selectedAccount) {
        currentForm = Object.assign(currentForm, {
          enabled: selectedAccount.enabled
        });
        if (editableForm === "depositForm") {
          this.depositStatusToggleRef.current.setState({ active: selectedAccount.enabled });
        } else if (editableForm === "payoutForm") {
          this.payoutStatusToggleRef.current.setState({ active: selectedAccount.enabled });
        }
      }
    }

    if (midId) {
      this.addLog(prevValue, value, editableField, editableForm, midId);
    }

    if (submitTouched) {
      currentForm.isFormValid();
    }

    this.setState({
      [editableForm]: currentForm,
      showPromptModal: false,
      promptModalTitle: "",
      promptModalMessage: "",
      editableField: "",
      editableFieldValue: null,
      editableForm: ""
    });
  };

  handleClosePromptModal = () => {
    this.setState({
      showPromptModal: false,
      promptModalTitle: "",
      promptModalMessage: "",
      editableField: "",
      editableFieldValue: null,
      editableForm: ""
    });
  };

  onMidSecureValueChange = (newValue, fieldName) => {
    let { midSecurity } = this.state;
    const { submitTouched } = this.state;
    const isNumber = midSecurity.fieldRules.find(rule => rule.name === fieldName).type === "isNumber";
    if (newValue.value) {
      midSecurity = Object.assign(midSecurity, {
        [fieldName]: isNumber && !isNaN(parseFloat(newValue.value)) ? parseFloat(newValue.value) : newValue.value
      });
    } else if (newValue.target) {
      midSecurity = Object.assign(midSecurity, {
        [fieldName]: isNumber && !isNaN(parseFloat(newValue.target.value)) ? parseFloat(newValue.target.value) : newValue.target.value
      });
    } else {
      midSecurity = Object.assign(midSecurity, {
        [fieldName]: newValue.map(elem => elem.value)
      });
    }

    if (submitTouched) {
      midSecurity.isFormValid();
    }

    this.setState({ midSecurity });
  };

  handleOptionChange = (value) => {
    const { midForm, weekDaysSchedule, submitTouched } = this.state;

    const fieldRule = midForm.fieldRules.find(rule => rule.name === "timeZone");
    fieldRule.rules.required = value === "custom" || midForm.maxDailyLoad > 0;
    if (submitTouched) {
      midForm.isFormValid();
    }

    this.setState({
      liveStatus: value,
      midForm: Object.assign(midForm, {
        weekDaysSchedule: value === "always" ? [] : weekDaysSchedule
      })
    });
  };

  onHolidayValue = (event, field) => {
    const { holidayControl } = this.state;
    holidayControl[field] = event.target.value;
    this.setState({
      holidayControl
    });
  };

  isDateValid = (current, state) => {
    const fromDate = this.state.timeStamp.fromDateTimeStamp;
    const toDate = this.state.timeStamp.toDateTimeStamp;
    const yesterday = Datetime.moment().utc().subtract(1, 'days');

    if (state === 'fromDateTimeStamp') {
      return current.isBefore(!fromDate ? yesterday : toDate);
    }

    return current.isAfter(!toDate ? yesterday : Datetime.moment(fromDate).subtract(1, 'days'));
  };

  handleDateChange = (event, state, fieldState) => {
    const holidayControl = { ...this.state.holidayControl };
    const timeStamp = { ...this.state.timeStamp };

    timeStamp[state] = event;

    const day = event._d.getDate() > 9 ? event._d.getDate() : '0' + event._d.getDate();
    const month = event._d.getMonth() + 1 > 9 ? event._d.getMonth() + 1 : '0' + (event._d.getMonth() + 1);
    holidayControl[fieldState] = `${day}.${month}.${event._d.getFullYear()}`;

    this.setState({ holidayControl: holidayControl, timeStamp: timeStamp, isSubmitting: false });
  };

  onAddHolidayRow = () => {
    let { holidayControl } = this.state;
    const { holidaysNewRowClicked, midForm } = this.state;

    if (!holidaysNewRowClicked && midForm.bankHolidaysSchedule.length > 0) {
      this.setState({
        holidaysNewRowClicked: true
      });

      return;
    }

    if (!holidayControl.holidayName || !holidayControl.from || !holidayControl.to) {
      this.setState({
        holidayErrors: {
          holidayName: !holidayControl.holidayName,
          from: !holidayControl.from,
          to: !holidayControl.to
        }
      });
    } else {
      holidayControl = Object.assign(holidayControl, {
        id: null,
        fromDate: holidayControl.from,
        toDate: holidayControl.to
      });

      delete holidayControl.from;
      delete holidayControl.to;

      midForm.bankHolidaysSchedule.push(holidayControl);

      this.setState({
        midForm: midForm,
        holidayControl: {
          holidayName: "",
          from: this.getPeriod(0).from.format("DD.MM.YYYY"),
          to: this.getPeriod(0).to.format("DD.MM.YYYY")
        },
        holidayErrors: {
          holidayName: false,
          from: false,
          to: false
        },
        holidaysNewRowClicked: true
      });
    }
  };

  timeDropdowns = (fieldName, dayName) => {
    const { midForm } = this.state;
    const weekDaysSchedule = midForm.weekDaysSchedule;
    const foundDay = weekDaysSchedule.find(day => day.dayName === dayName);
    const value = parseInt(foundDay[fieldName].split(":")[0]);
    const result = [];
    for (let i = 0; i < 24; i++) {
      if ((fieldName === "fromDate" && i >= value) ||
        (fieldName === "toDate" && i <= value)) {
        result.push({
          value: `${i < 10 ? "0" + i : i}:00`,
          label: `${i < 10 ? "0" + i : i}:00`
        });
      }
    }

    if (fieldName === "fromDate") {
      result.push({
        value: "23:59",
        label: "23:59"
      });
    }

    return result;
  };

  onDeleteHolidayRow = (element) => {
    const { midForm } = this.state;

    if (!element) {
      this.setState({
        holidaysNewRowClicked: false,
        holidayControl: {
          holidayName: "",
          from: this.getPeriod(0).from.format("DD.MM.YYYY"),
          to: this.getPeriod(0).to.format("DD.MM.YYYY")
        },
      });

      return;
    }

    const deleteIndex = midForm.bankHolidaysSchedule.findIndex(elem => {
      return elem.holidayName === element.holidayName &&
        elem.from === element.from &&
        elem.to === element.to;
    });

    midForm.bankHolidaysSchedule.splice(deleteIndex, 1);

    this.setState({
      midForm: midForm
    });
  };

  onDayDateChange = (event, fieldName, dayName) => {
    const { midForm } = this.state;
    const { weekDaysSchedule } = midForm;
    const foundDay = weekDaysSchedule.find(day => day.dayName === dayName);
    foundDay[fieldName] = event.value;
    this.setState({
      midForm: midForm
    });
  };

  onMidSecurityClick = (isActive) => {
    this.setState({
      isMidSecurityEnabled: isActive
    });
  };

  defineWeekDayBackground = (index) => {
    if (index === 2 || index === 3 || index === 6) {
      return true;
    }

    return false;
  };

  onEmailsChange = (id, items) => {
    const { midSecurity } = this.state;
    this.setState({
      midSecurity: Object.assign(midSecurity, { emails: items }),
      temporaryEmail: ""
    });
  };

  onTemporaryEmailsChange = (id, value) => {
    this.setState({
      temporaryEmail: value
    });
  };

  isPayoutFormEmpty = () => {
    const { payoutForm } = this.state;
    const formData = payoutForm.data();
    let isEmpty = true;
    if (formData) {
      Object.values(formData).forEach(value => {
        if (value) {
          isEmpty = false;
          return;
        }
      });
    }

    return isEmpty;
  };

  checkIsVaderPay = (pspId) => {
    const { midForm, psps } = this.state;
    if (!pspId) {
      return false;
    }
    const selectedProvider = psps[midForm.companyId].find(psp => psp.value === pspId);
    if (selectedProvider) {
      return selectedProvider.shortName === "VADER_PAY";
    }
    return false;
  };

  onTogglerClick = (value, type) => {
    const { foundMid, midForm } = this.state;
    const currentFormName = `${type}Form`;
    const currentForm = this.state[currentFormName];
    if (!midForm.id || !foundMid.merchants || !foundMid[`${type}Settings`].pspId) {
      return this.setState({
        [currentFormName]: Object.assign(currentForm, {
          enabled: value
        })
      });
    }

    const changes = addLogItem(
      [],
      `MID_UPDATE_STATUS_${type.toUpperCase()}`,
      currentForm.enabled ? "on" : "off",
      value ? "on" : "off",
      "enabled",
      midForm.id
    );
    this.setState({
      isStatusLoading: type
    });

    crudActions.patch(`v1/mids`, {
      enabled: value,
      type,
      id: midForm.id,
      changes,
      companyId: midForm.companyId
    }).then(
      () => {
        this.setState({
          [currentFormName]: Object.assign(currentForm, {
            enabled: value
          }),
          isStatusLoading: ""
        });
      }
    ).catch(
      err => {
        if (err && err.message) {
          this.setState({
            showAlert: true,
            alertType: "error",
            alertMessage: err.message,
            isStatusLoading: ""
          });
        }
      }
    );
  };

  onSubmitMid = () => {
    const {
      depositForm,
      isMidSecurityEnabled,
      logs,
      midForm,
      midSecurity,
      payoutForm,
      temporaryEmail,
      testValuesPermitted
    } = this.state;

    const { midId } = this.props;

    const isMidFormValid = midForm.isFormValid();
    const isDepositFormValid = depositForm.isFormValid();
    const isPayoutFormValid = payoutForm.isFormFieldValid("pspId", null, false) &&
      payoutForm.isFormFieldValid("accountId", null, false) &&
      payoutForm.isFormFieldValid("paymentMethod", null, false) &&
      payoutForm.isFormFieldValid("mode", null, false) &&
      payoutForm.isFormFieldValid("minAmount", null, false) &&
      payoutForm.isFormFieldValid("maxAmount", null, false) &&
      payoutForm.isFormFieldValid("payoutWalletType", null, false);
    const isMidSecurityFormValid = midSecurity.isFormValid();

    this.setState({
      midForm,
      depositForm,
      isLoading: true,
      submitTouched: true,
      removeEmptyField: true
    });

    this.onAddHolidayRow();

    if (isMidFormValid && isDepositFormValid && isMidSecurityFormValid) {
      this.setState({
        isSubmitting: true
      });
      const data = midForm.data();
      let depositFormData = depositForm.data();
      const payoutFormData = payoutForm.data();
      depositFormData = {
        ...depositFormData,
        updateAmount: depositFormData.updateAmount === "final"
      };
      const midSecurityData = midSecurity.data();
      delete data.midUsage;
      delete data.depositSettings;
      delete data.payoutSettings;

      if (temporaryEmail && !midSecurityData.emails.find(email => email === temporaryEmail)) {
        midSecurityData.emails.push(temporaryEmail);
      }

      crudActions.post("v1/mids", Object.assign(data, {
        depositSettings: depositFormData,
        ...(isPayoutFormValid && { payoutSettings: payoutFormData }),
        midsSecurityDTO: Object.assign(midSecurityData, {
          enabled: isMidSecurityEnabled,
          withTest: testValuesPermitted
        }),
        ...(midId && { companyId: midForm.companyId }),
        ...(midId && { changes: logs.filter(log => log.parameters.length) })
      })).then(
        () => {
          this.setState({
            showAlert: true,
            alertType: "success",
            alertMessage: "Settings successfully saved.",
            isLoading: false,
            temporaryEmail: "",
            removeEmptyField: false,
            isSubmitting: false
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false,
              isSubmitting: false
            });
          }

          this.setState({
            removeEmptyField: false
          });
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them.",
        isLoading: false
      });
    }
  };

  onCloneMid = () => {
    const {
      depositForm,
      isMidSecurityEnabled,
      midForm,
      midSecurity,
      payoutForm,
      temporaryEmail,
      testValuesPermitted
    } = this.state;

    const isMidFormValid = midForm.isFormValid();
    const isDepositFormValid = depositForm.isFormValid();
    const isPayoutFormValid = payoutForm.isFormFieldValid("pspId", null, false) &&
      payoutForm.isFormFieldValid("accountId", null, false) &&
      payoutForm.isFormFieldValid("paymentMethod", null, false) &&
      payoutForm.isFormFieldValid("mode", null, false) &&
      payoutForm.isFormFieldValid("minAmount", null, false) &&
      payoutForm.isFormFieldValid("maxAmount", null, false) &&
      payoutForm.isFormFieldValid("payoutWalletType", null, false);
    const isMidSecurityFormValid = midSecurity.isFormValid();

    this.setState({
      midForm,
      depositForm,
      isLoading: true
    });

    if (isMidFormValid && isDepositFormValid && isMidSecurityFormValid) {
      this.setState({
        isSubmitting: true
      });
      const data = midForm.data();
      let depositFormData = depositForm.data();
      const payoutFormData = payoutForm.data();
      depositFormData = {
        ...depositFormData,
        updateAmount: data.updateAmount === "final"
      };
      const midSecurityData = midSecurity.data();
      delete data.midUsage;
      delete data.depositSettings;
      delete data.payoutSettings;

      if (temporaryEmail && !midSecurityData.emails.find(email => email === temporaryEmail)) {
        midSecurityData.emails.push(temporaryEmail);
      }
      
      crudActions.post("v1/mids/clone", Object.assign(data, {
        depositSettings: depositFormData,
        ...(isPayoutFormValid && { payoutSettings: payoutFormData }),
        midsSecurityDTO: Object.assign(midSecurityData, {
          enabled: isMidSecurityEnabled,
          withTest: testValuesPermitted
        })
      })).then(
        (mid) => {
          this.setState({
            isSubmitting: false
          }, () => {
            window.location.href = `/edit-mid/${mid.id}`;
          });
        }
      ).catch(
        err => {
          if (err && err.message) {
            this.setState({
              showAlert: true,
              alertType: "error",
              alertMessage: err.message,
              isLoading: false,
              isSubmitting: false
            });
          }
        }
      );
    } else {
      this.setState({
        showAlert: true,
        alertType: "error",
        alertMessage: "Some fields have errors. Please, fix them.",
        isLoading: false
      });
    }
  };

  onConfirm = () => {
    this.setState({
      showAlert: false,
      alertType: "success",
      alertMessage: "",
      redirectToMids: this.state.showAlert && this.state.alertType === "success"
    });
  };

  filterPspByCurrency = (currency, psps) => {
    return psps.filter(psp => psp.currencies.includes(currency));
  };

  isMethodDisabled = (field, accountId) => {
    const { accounts, midForm } = this.state;
    const account = accounts[midForm.companyId]?.find(acc => acc.value === accountId);

    return account?.[field] !== "DEFAULT";
  };

  render() {
    const {
      cardTypes,
      companies,
      depositForm,
      foundMid,
      isBurgerOpen,
      isLoading,
      isStatusLoading,
      isSubmitting,
      merchants,
      midForm,
      midsLinks,
      payoutForm,
      payoutWalletTypes,
      psps,
      pspUrlsData,
      redirectToMids,
      showAlert,
      alertType,
      alertMessage,
      showPromptModal,
      promptModalTitle,
      promptModalMessage,
      trafficTypes
    } = this.state;

    const { viewOrEdit } = this.props;

    const isFieldDisabled = this.isFieldDisabled();

    const isSuccessfulDepositExists = midForm.successfulDepositExists;
    const isPendingPayoutExists = midForm.pendingPayoutExists;
    const isSuccessfulPayoutExists = midForm.successfulPayoutExists;

    const isMerchantFieldDisabled = isFieldDisabled || !midForm.companyId ||
      foundMid.availableBalance > 0 || foundMid.payoutWalletBalance > 0 ||
      isSuccessfulDepositExists || isPendingPayoutExists ||
      isSuccessfulPayoutExists;

    const isVaderPay = this.checkIsVaderPay(depositForm.pspId);

    const timeZoneSelection = (disable) => {
      return <Column vertical='start' alignSelf='start' className="input-column">
        <label> Time Zone </label>
        <Select
          value={ midForm.timeZone || '' }
          className={ midForm.errors.has('timeZone') ? 'error-field' : "" }
          required={ true }
          clearable={ false }
          disabled={ disable }
          enableSort={ false }
          onChange={ (value) => this.onValueChange(value, 'midForm', 'timeZone') }
          options={ this.getAvailableTimeZones() }
        />
        { midForm.errors.has('timeZone') &&
          <span className="error-message"> { midForm.errors.get("timeZone") }  </span> }
      </Column>;
    };

    if (redirectToMids) {
      return <Redirect to='/mids' />;
    }

    return (
      <Row flexGrow={ 1 } className="module mids" vertical='start'>
        <Column flexGrow={ 1 }>
          <Row className="header" flexGrow={ 1 } horizontal='space-between' vertical='center'>
            <Column>
              <Row horizontal='center' vertical='center' style={ { paddingLeft: 15 } }>
                <img src={ MidsIcon } alt="" style={ { marginRight: 10 } } />
                MIDs
              </Row>
            </Column>
            <Column horizontal='end'>
              <Row horizontal='end' vertical='center'>
                <Column horizontal='end' style={ { paddingRight: 15 } }>
                  <Row horizontal='end' vertical='center' style={ { color: '#ccc', fontSize: '12px' } }>
                    <NavLink to={ `/add-mid` } className="btn add-deposit-button">
                      ADD MIDs
                    </NavLink>
                    <div ref={ node => this.burgerNode = node } className={ "burger-container " + (isBurgerOpen ? "change" : "") } style={ { float: "right" } } onClick={ this.onBurgerClick }>
                      <div className="burger-bar1"></div>
                      <div className="burger-bar2"></div>
                      <div className="burger-bar3"></div>
                    </div>
                    {isBurgerOpen && (
                      <div ref={ node => this.node = node } className="burger-content">
                        {midsLinks.map((link, i) => {
                          return (
                            <div key={ i } className="link-item-container">
                              <NavLink
                                to={ link.url }
                                className="link-item"
                                onClick={ () => this.handleClickOutside() }
                              >
                                {link.name}
                              </NavLink>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </Row>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row flexGrow={ 1 } horizontal='start' wrap={ true } vertical='start'>
            <Column flexGrow={ 1 } vertical='start' className="panel-block">
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> MIDs </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      MIDs
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> White Label </label>
                          <Select
                            value={ midForm.companyId || '' }
                            className={ midForm.errors.has('companyId') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || viewOrEdit === 2 }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'companyId') }
                            options={ companies }
                          />
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Merchant </label>
                          <Select
                            value={ midForm.merchants || '' }
                            className={ midForm.errors.has('merchants') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isMerchantFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'merchants') }
                            options={ midForm.companyId ? merchants[midForm.companyId] : [] }
                          />
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> MID name </label>
                          {midForm.midName && (
                            <Tooltip id="midName" />
                          )}
                          <div
                            data-tooltip-id="midName"
                            data-tooltip-place="top"
                            data-tooltip-variant="light"
                            data-tooltip-content={ midForm.midName ? midForm.midName : null }>
                            <input
                              className={ "form-control " + (midForm.errors.has('midName') ? 'error-field' : "") }
                              value={ midForm.midName || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'midForm', 'midName') } />
                            {midForm.errors.has('midName') && <span className="error-message"> {midForm.errors.get("midName")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Traffic </label>
                          <Select
                            value={ midForm.trafficType || '' }
                            className={ midForm.errors.has('trafficType') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'trafficType') }
                            options={ trafficTypes }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Category </label>
                          <Select
                            value={ midForm.category || '' }
                            className={ midForm.errors.has('category') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'category') }
                            options={ this.formLookups("midCategories", true) }
                          />
                          {midForm.errors.has('category') && <span className="error-message"> {midForm.errors.get("category")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Currency </label>
                          <Select
                            value={ midForm.currency || '' }
                            className={ midForm.errors.has('currency') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'currency') }
                            options={ this.formLookups("currency", false, "currency") }
                          />
                          {midForm.errors.has('currency') && <span className="error-message"> {midForm.errors.get("currency")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Country </label>
                          <Multiselect
                            isError={ midForm.errors.has('countries') }
                            selectedItems={ this.mapSelectedItems("countries", "country") }
                            items={ this.formLookups(this.mapCountries("BUY"), false, "country") }
                            disabled={ isFieldDisabled || !midForm.companyId }
                            type={ "countries" }
                            showTooltip={ true }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'countries') } />
                          {midForm.errors.has('countries') && <span className="error-message"> {midForm.errors.get("countries")}  </span>}
                        </Column>
                        {midForm.paymentMethod === "CREDIT_CARD" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Card types </label>
                            <Multiselect
                              isError={ midForm.errors.has('supportedCardTypes') }
                              selectedItems={ this.mapSelectedItems("supportedCardTypes", "cardTypes") }
                              items={ cardTypes }
                              disabled={ isFieldDisabled || !midForm.companyId }
                              type={ "supportedCardTypes" }
                              showTooltip={ true }
                              onChange={ (value) => this.onValueChange(value, 'midForm', 'supportedCardTypes') } />
                          </Column>
                        )}
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Transaction expiration time </label>
                          <Select
                            value={ midForm.expiredInMinutes || '' }
                            className={ midForm.errors.has('expiredInMinutes') ? 'error-field' : "" }
                            required={ false }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            enableSort={ false }
                            onChange={ (value) => this.onValueChange(value, 'midForm', 'expiredInMinutes') }
                            options={ trxExpirationTimeOptions }
                          />
                          {midForm.errors.has('expiredInMinutes') && <span className="error-message"> {midForm.errors.get("expiredInMinutes")}  </span>}
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> DEPOSIT SETTINGS </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      DEPOSIT SETTINGS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <Select
                            value={ depositForm.pspId || '' }
                            className={ depositForm.errors.has('pspId') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId || isSuccessfulDepositExists }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'pspId') }
                            options={ !midForm.companyId || !midForm.currency ? [] : this.filterPspByCurrency(midForm.currency, psps[midForm.companyId]) }
                          />
                          {depositForm.errors.has('pspId') && <span className="error-message"> {depositForm.errors.get("pspId")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Account </label>
                          <Select
                            value={ depositForm.accountId || '' }
                            className={ depositForm.errors.has('accountId') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId || isSuccessfulDepositExists }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'accountId') }
                            options={ !midForm.companyId || !midForm.currency ? [] : this.filterAvailableAccounts('depositForm') }
                          />
                          {depositForm.errors.has('accountId') && <span className="error-message"> {depositForm.errors.get("accountId")}  </span>}
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Deposit Method </label>
                          <Select
                            value={ depositForm.paymentMethod || '' }
                            className={ depositForm.errors.has('paymentMethod') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled || !midForm.companyId || this.isMethodDisabled("payinMethod", depositForm.accountId)  }
                            onChange={ (value) => this.onValueChange(value, 'depositForm', 'paymentMethod') }
                            options={ this.mapMethods('BUY', 'depositForm') || [] }
                          />
                          {depositForm.errors.has('paymentMethod') && <span className="error-message"> {depositForm.errors.get("paymentMethod")}  </span>}
                        </Column>
                        {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Provider URLs </label>
                            <Select
                              value={ depositForm.mode }
                              className={ depositForm.errors.has('mode') ? 'error-field' : "" }
                              required={ true }
                              clearable={ false }
                              disabled={ isFieldDisabled || !midForm.companyId }
                              onChange={ (value) => this.onValueChange(value, 'depositForm', 'mode') }
                              options={ pspUrlsData }
                            />
                          </Column>
                        )}
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label style={ { marginBottom: "10px" } }>
                            Status
                          </label>
                          <Toggler
                            ref={ this.depositStatusToggleRef }
                            active={ depositForm.enabled }
                            disabled={ isFieldDisabled || !this.isAccountEnabled('depositForm') || !midForm.merchants || isStatusLoading === "deposit" }
                            value="deposit"
                            onClick={ this.onTogglerClick }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Min Tx Amount </label>
                          <div data-tip={ depositForm.minAmount ? depositForm.minAmount : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('minAmount') ? 'error-field' : "") }
                              value={ depositForm.minAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'minAmount') } />
                            {depositForm.errors.has('minAmount') && <span className="error-message"> {depositForm.errors.get("minAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Tx Amount </label>
                          <div>
                            <input
                              className={ "form-control " + (depositForm.errors.has('maxAmount') ? 'error-field' : "") }
                              value={ depositForm.maxAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'maxAmount') } />
                            {depositForm.errors.has('maxAmount') && <span className="error-message"> {depositForm.errors.get("maxAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Daily Load </label>
                          {!!depositForm.maxDailyLoad && (
                            <Tooltip id="maxDailyLoad" />
                          )}
                          <div
                            data-tooltip-id="maxDailyLoad"
                            data-tooltip-place="top"
                            data-tooltip-variant="light"
                            data-tooltip-content={ depositForm.maxDailyLoad ? depositForm.maxDailyLoad : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('maxDailyLoad') ? 'error-field' : "") }
                              value={ depositForm.maxDailyLoad || '' }
                              type="number"
                              placeholder="Unlimited"
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'maxDailyLoad') } />
                            {depositForm.errors.has('maxDailyLoad') && <span className="error-message"> {depositForm.errors.get("maxDailyLoad")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Rounding </label>
                          {!!depositForm.rounding && (
                            <Tooltip id="rounding" />
                          )}
                          <div
                            data-tooltip-id="rounding"
                            data-tooltip-place="top"
                            data-tooltip-variant="light"
                            data-tooltip-content={ depositForm.rounding ? depositForm.rounding : null }>
                            <input
                              className={ "form-control " + (depositForm.errors.has('rounding') ? 'error-field' : "") }
                              value={ depositForm.rounding || "" }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'depositForm', 'rounding') } />
                            {depositForm.errors.has('rounding') && <span className="error-message"> {depositForm.errors.get("rounding")}  </span>}
                          </div>
                        </Column>
                      </Row>
                      <Row>
                        {isVaderPay && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Update Amount </label>
                            <Select
                              value={ depositForm.updateAmount || '' }
                              className={ depositForm.errors.has('updateAmount') ? 'error-field' : "" }
                              required={ true }
                              clearable={ false }
                              disabled={ isFieldDisabled }
                              enableSort={ false }
                              onChange={ (value) => this.onValueChange(value, 'depositForm', 'updateAmount') }
                              options={ [{ value: 'initial', label: 'Initial amount' }, { value: 'final', label: 'Final amount' }] }
                            />
                          </Column>
                        )}
                        { timeZoneSelection(false) }
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
              
              {isLoading ? (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title> PAYOUT SETTINGS </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div style={ { width: "100%", height: "400px", display: "flex", alignItems: "center", justifyContent: "center" } }>
                      <Spinner smallContainer={ true } />
                    </div>
                  </Panel.Body>
                </Panel>
              ) : (
                <Panel>
                  <Panel.Heading>
                    <Panel.Title>
                      PAYOUT SETTINGS
                    </Panel.Title>
                  </Panel.Heading>
                  <Panel.Body>
                    <div className="panel-content" style={ { overflow: 'unset' } }>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Provider </label>
                          <div data-tip={ isPendingPayoutExists || isSuccessfulPayoutExists ? "Locked due to successful/in progress payouts" : null }>
                            <Select
                              value={ payoutForm.pspId || '' }
                              className={ payoutForm.errors.has('pspId') ? 'error-field' : "" }
                              required={ true }
                              clearable={ true }
                              disabled={ isFieldDisabled || !midForm.companyId || isPendingPayoutExists || isSuccessfulPayoutExists }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'pspId') }
                              options={ !midForm.companyId ? [] : psps[midForm.companyId] }
                            />
                            {payoutForm.errors.has('pspId') && <span className="error-message"> {payoutForm.errors.get("pspId")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Account </label>
                          <div data-tip={ isPendingPayoutExists || isSuccessfulPayoutExists ? "Locked due to successful/in progress payouts" : null }>
                            <Select
                              value={ payoutForm.accountId || '' }
                              className={ payoutForm.errors.has('accountId') ? 'error-field' : "" }
                              required={ true }
                              clearable={ true }
                              disabled={ isFieldDisabled || !midForm.companyId || isPendingPayoutExists || isSuccessfulPayoutExists }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'accountId') }
                              options={ !midForm.companyId ? [] : this.filterAvailableAccounts('payoutForm') }
                            />
                            {payoutForm.errors.has('accountId') && <span className="error-message"> {payoutForm.errors.get("accountId")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Method </label>
                          <Select
                            value={ payoutForm.paymentMethod || '' }
                            className={ payoutForm.errors.has('paymentMethod') ? 'error-field' : "" }
                            required={ true }
                            clearable={ true }
                            disabled={ isFieldDisabled || !midForm.companyId || this.isMethodDisabled("payoutMethod", payoutForm.accountId) }
                            onChange={ (value) => this.onValueChange(value, 'payoutForm', 'paymentMethod') }
                            options={ this.mapMethods('PAYOUT', 'payoutForm') || [] }
                          />
                          {payoutForm.errors.has('paymentMethod') && <span className="error-message"> {payoutForm.errors.get("paymentMethod")}  </span>}
                        </Column>
                        {store.default.getState().authReducer.roleId === "MASTER_TECH" && (
                          <Column vertical='start' alignSelf='start' className="input-column">
                            <label> Provider URLs </label>
                            <Select
                              value={ payoutForm.mode }
                              className={ payoutForm.errors.has('mode') ? 'error-field' : "" }
                              required={ true }
                              clearable={ false }
                              disabled={ isFieldDisabled || !midForm.companyId }
                              onChange={ (value) => this.onValueChange(value, 'payoutForm', 'mode') }
                              options={ pspUrlsData }
                            />
                          </Column>
                        )}
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label style={ { marginBottom: "10px" } }>
                            Status
                          </label>
                          <Toggler
                            ref={ this.payoutStatusToggleRef }
                            active={ payoutForm.enabled }
                            disabled={ isFieldDisabled || !this.isAccountEnabled('payoutForm') || !midForm.merchants || isStatusLoading === "payout" }
                            value="payout"
                            onClick={ this.onTogglerClick }
                          />
                        </Column>
                      </Row>
                      <Row flexGrow={ 1 } wrap={ true } horizontal='start' vertical='end' style={ { width: '100%' } }>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Min Tx Amount </label>
                          <div data-tip={ payoutForm.minAmount ? payoutForm.minAmount : null }>
                            <input
                              className={ "form-control " + (payoutForm.errors.has('minAmount') ? 'error-field' : "") }
                              value={ payoutForm.minAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'minAmount') } />
                            {payoutForm.errors.has('minAmount') && <span className="error-message"> {payoutForm.errors.get("minAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Max Tx Amount </label>
                          <div>
                            <input
                              className={ "form-control " + (payoutForm.errors.has('maxAmount') ? 'error-field' : "") }
                              value={ payoutForm.maxAmount || '' }
                              disabled={ isFieldDisabled }
                              onChange={ (event) => this.onValueChange(event, 'payoutForm', 'maxAmount') } />
                            {payoutForm.errors.has('maxAmount') && <span className="error-message"> {payoutForm.errors.get("maxAmount")}  </span>}
                          </div>
                        </Column>
                        <Column vertical='start' alignSelf='start' className="input-column">
                          <label> Payout Wallet Type </label>
                          <Select
                            value={ payoutForm.payoutWalletType || '' }
                            className={ payoutForm.errors.has('payoutWalletType') ? 'error-field' : "" }
                            required={ true }
                            clearable={ false }
                            disabled={ isFieldDisabled }
                            onChange={ (value) => this.onValueChange(value, 'payoutForm', 'payoutWalletType') }
                            options={ payoutWalletTypes }
                          />
                          {payoutForm.errors.has('payoutWalletType') && <span className="error-message"> {payoutForm.errors.get("payoutWalletType")}  </span>}
                        </Column>
                        { timeZoneSelection(true) }
                      </Row>
                      <Row flexGrow={ 1 } horizontal='end' wrap={ true } vertical='start'>
                        <Column flexGrow={ 0 } vertical='end' className="button-block input-column">
                          {!isFieldDisabled && (
                            <CustomButton
                              title="Clone Mid"
                              type="submit"
                              className="btn"
                              disabled={ isSubmitting }
                              onClick={ () => this.onCloneMid() }
                            />
                          )}
                        </Column>
                        <Column flexGrow={ 0 } vertical='end' className="button-block input-column">
                          {!isFieldDisabled && (
                            <CustomButton
                              title="Save"
                              type="submit"
                              disabled={ isSubmitting }
                              onClick={ () => this.onSubmitMid() }
                            />
                          )}
                        </Column>
                      </Row>
                    </div>
                  </Panel.Body>
                </Panel>
              )}
            </Column>
          </Row>
        </Column>

        {showAlert && (
          <Alert
            show={ showAlert }
            title={ alertType }
            type={ alertType }
            text={ alertMessage }
            confirmButtonColor="#187EED"
            onConfirm={ this.onConfirm }
          />
        )}

        <PromptModal
          title={ promptModalTitle }
          message={ promptModalMessage }
          handleConfirm={ this.handleConfirmPromptModal }
          handleClose={ this.handleClosePromptModal }
          isLoading={ false }
          showModal={ showPromptModal }
        />
      </Row>
    );
  }
}

export default ManageMid;
